import { api } from "@/api";

/**
 * Do a Journal.
 *
 * @param stepId
 * @param response
 * @returns {AxiosPromise<any>}
 */
async function doJournal(stepId, response) {
  return api.post(`journal/${stepId}`, {
    response
  });
}

/**
 * Upload file Journal.
 *
 * @param stepId
 * @param file
 * @returns {AxiosPromise<any>}
 */
async function uploadFile(stepId, file) {
  const config = {
    headers: { "content-type": "multipart/form-data" }
  };
  let formData = new FormData();
  formData.append("file", file);
  return api.post(`journal/${stepId}`, formData, config);
}

export const JournalProvider = {
  doJournal,
  uploadFile
};
