import { router } from "@/router";
import { Url } from "@/helpers/Url";

/**
 * Is the input a valid Email?
 * @param  {string} email
 * @returns  {boolean}
 */
function isValidEmail(email) {
  // eslint-disable-next-line
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  return regexEmail.test(email);
}

/**
 * Navigate to SMS Login page.
 *
 * @returns {boolean}
 */
function gotoSMSLogin() {
  let route = "/login/sms";

  const appId = getAppId();
  if (appId > 0) {
    route += `?app-id=${appId}`;
  }

  router.push(route);

  return false;
}

/**
 * Navigate to Email Login page.
 *
 * @returns {boolean}
 */
function gotoEmailLogin(appId = 0) {
  let route = "/login";

  if (appId === 0) {
    appId = getAppId();
  }

  if (appId > 0) {
    route += `?app-id=${appId}`;
  }

  router.push(route);

  return false;
}

/**
 * Get App Id from Url.
 *
 * @returns {number}
 */
function getAppId() {
  const appId = Url.getParam("app-id");
  if (appId === null) {
    return 0;
  }

  return parseInt(appId);
}

export const Login = {
  isValidEmail,
  gotoSMSLogin,
  gotoEmailLogin,
  getAppId
};
