import Vue from "vue";

import DrawerLayout from "vue-drawer-layout";

import Vant from "vant";
import "vant/lib/index.css";

import VueCountryCode from "vue-country-code";

import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";

Vue.use(DrawerLayout);
Vue.use(Vant);

Vue.use(VueCountryCode);

Locale.use("en-US", enUS);
