<template>
  <div>
    <div class="task-list">
      <p v-if="status === 'new'">
        Select the task you want to enter today.
      </p>
      <p v-if="status === 'completed'">
        Select your completed task to celebrate them.
      </p>
    </div>

    <van-skeleton :row="10" :loading="!tasksLoaded" />

    <TaskListItem v-for="task in tasks" :task="task" :key="task.id" />

    <p v-if="tasksLoaded && tasks.length <= 0">
      <span v-if="status === 'new'">
        You have completed all your tasks today. Good job!
      </span>
      <span v-if="status === 'completed'">
        Yet to complete a task today.
      </span>
    </p>
  </div>
</template>

<script>
import TaskListItem from "@/components/TaskListItem.vue";
import "@vant/touch-emulator";
import { TaskProvider } from "@/providers";

export default {
  name: "TaskList",
  components: { TaskListItem },

  props: {
    status: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tasks: [],
      tasksLoaded: false
    };
  },

  created() {
    window.Event.listen(
      "TaskJournalingCompleted",
      this.taskJournalingCompleted
    );
  },

  mounted() {
    this.getTasks();
  },

  methods: {
    taskJournalingCompleted() {
      this.getTasks();
    },

    getTasks() {
      const self = this;
      TaskProvider.getTasks(this.status).then(response => {
        self.tasksLoaded = true;
        self.tasks = response;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
