export const ImpactKinds = {
  NegativeEmotion: "Negative Emotion",
  HightTAT: "High TAT",
  OppossesIntention: "Opposses Intention"
};

export class ImpactKind {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }
}
