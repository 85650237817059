<template>
  <div class="timer" style="font-size: 14px; margin-top:10px">
    {{ time | prettify }}
  </div>
</template>
<script>
export default {
  name: "Timer",
  props: ["time"],
  filters: {
    prettify(value) {
      let data = value.split(":");
      let minutes = data[0];
      let seconds = data[1];
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return `${"Resend OTP in " + minutes + ":" + seconds}`;
    }
  }
};
</script>
