import { api } from "@/api";

/**
 * Get user routine steps count.
 *
 * @returns {AxiosPromise<any>}
 */
async function getRoutineStepsCount(routineId) {
  const response = await api.get(`routine/steps/count/${routineId}`);
  return response.data;
}

export const RoutineProvider = {
  getRoutineStepsCount
};
