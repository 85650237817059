export class Journey {
  constructor(
    id,
    functionalArea,
    title,
    customer,
    intentions = [],
    steps = [],
    extremeObjective
  ) {
    this.id = id;
    this.functionalArea = functionalArea;
    this.title = title;
    this.customer = customer;
    this.intentions = intentions;
    this.steps = steps;
    this.extremeObjective = extremeObjective;
  }
}
