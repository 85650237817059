export class JourneyStep {
  constructor(order, title = null, description = null, impacts = [], comment) {
    this.order = order;
    this.title = title;
    this.description = description;
    this.impacts = impacts;
    this.comment = comment;
  }

  score() {
    let rating = 0;
    this.impacts.forEach(impact => {
      rating += impact.score.score;
    });
    return rating / this.impacts.length;
  }

  isTrimTab() {
    return this.score() > 2;
  }
}
