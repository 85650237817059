import { api } from "@/api";

async function getNotifications() {
  const response = await api.get("notifications");
  return response.data;
}

async function markNotificationAsRead(notificationId) {
  return api.post(`notifications/${notificationId}`, {
    notificationId
  });
}

async function getUnreadNotificationsCount() {
  const response = await api.get("notifications/unread-count");
  return response.data;
}

export const NotificationProvider = {
  getNotifications,
  markNotificationAsRead,
  getUnreadNotificationsCount
};
