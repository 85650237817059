import { api } from "@/api";
import { CurrentUser } from "@/helpers";

function updateUser(user) {
  let userObject = CurrentUser.get();

  userObject.name = user.name;

  CurrentUser.set(userObject);

  return userObject;
}

async function getProfile() {
  const response = await api.get("profile");

  return response.data;
}

async function update(requestConfig = {}, params) {
  const response = await api.put(
    "profile",
    {
      name: params.name,
      magiq_word: params.magiq_word,
      oldEmail: params.oldEmail,
      newEmail: params.newEmail,
      changeLogin: params.loginMethod,
      email: params.email
    },
    {
      ...requestConfig
    }
  );
  let profile = response.data;
  updateUser(profile);
}

async function getUserPreference() {
  const response = await api.get("profile");

  return response.data;
}

export const ProfileProvider = {
  getProfile,
  update,
  getUserPreference
};
