<template>
  <div class="cj-component">
    <div class="van-doc-demo-block">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">Your CJMs</h2>
        <div
          v-for="journey in journeys"
          v-bind:key="journey.id"
          @click="handleSelectJourney(journey)"
          class="
            van-cell-group
            van-hairline--top-bottom
            van-doc-demo-block__card
          "
        >
          <div class="van-cell van-cell--clickable van-cell--borderless">
            <div
              class="
                van-cell__value van-cell__value--alone
              "
            >
              <div class="order-one">
                <div>
                  <div class="van-address-item__name">{{ journey.title }}</div>
                  <div class="van-address-item__address">
                    {{ journey.functionalArea }}
                  </div>
                </div>
                <div class="button-area">
                  <button
                    class="delete-button"
                    @click="handleDeleteJourney($event, journey)"
                  >
                    <i class="van-icon van-icon-delete edit-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="journeys.length == 0" style="margin: 16px">
          <!-- <van-empty></van-empty> -->
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="handleCreateJourney"
            >Get Started</van-button
          >
        </div>
        <div v-if="journeys.length > 0" style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="handleCreateJourney"
            >New CJM</van-button
          >
        </div>
        <div style="margin: 16px">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            @click="handleClose"
            >Close</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
// import { Empty } from "vant";
import { CustomerJourneyProvider } from "@/providers/CustomerJourneyProvider";

export default {
  name: "CustomerJourneyMapsHub",
  components: {
    [Dialog.name]: Dialog
    // [Empty.name]: Empty
  },
  props: {
    onCreateJourney: Function,
    onSelectJourney: Function,
    onEditJourney: Function,
    onClose: Function
  },
  data() {
    return {
      exampleJourney: null,
      journeys: []
    };
  },

  async created() {
    await this.loadCustomerJourneys();
  },

  methods: {
    async loadCustomerJourneys() {
      this.journeys = await new CustomerJourneyProvider().journeys();
    },

    handleClose() {
      this.onClose();
    },

    handleCreateJourney(journey) {
      this.onCreateJourney(journey);
    },

    handleSelectJourney(journey) {
      this.onSelectJourney(journey);
    },

    handleEditJourney(event, journey) {
      event.stopPropagation();
      this.onEditJourney(journey);
    },

    async handleDeleteJourney(event, journey) {
      event.stopPropagation();
      Dialog.confirm({
        title: "Delete Customer Journey",
        message: "Are you sure to delete Customer Journey?"
      }).then(async () => {
        await new CustomerJourneyProvider().deleteJourney(journey);
        await this.loadCustomerJourneys();
      });
    }
  }
};
</script>

<style src="@/assets/customerjourney/styles/vantdemo.css" />
