var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cj-component stage-two"},[_c('div',{staticClass:"list-wrapper"},[_c('div',{staticClass:"red-line"}),_vm._l((_vm.steps),function(step){return _c('div',{key:step.title},[(!step.isEditing && !step.isComposing)?_c('div',{class:'journey-card left animate-top' +
            (step.isTrimTab() ? ' trim-tab' : '')},[_c('div',{class:'list-bullet big-bullet ' +
              (step.impacts.length == 3
                ? _vm.cumulativeScoreOfStep(step).color
                : 'black') +
              '-bullet'},[_c('img',{attrs:{"src":require('@/assets/customerjourney/white-icon/' +
                (step.impacts.length == 3
                  ? _vm.cumulativeScoreOfStep(step).id
                  : 'default') +
                '.svg'),"alt":""}})]),_c('div',{staticClass:"list-item compl-list"},[_c('div',{staticClass:"fill-order-one"},[_c('div',{staticClass:"content-area"},[(step.isTrimTab())?_c('div',{staticClass:"chip"},[_c('span',[_vm._v("Trim Tab")])]):_vm._e(),_c('h4',[_vm._v(_vm._s(step.title))]),_c('h6',[_vm._v(_vm._s(step.description))]),_c('h6',[_vm._v(_vm._s(step.comment))])])])])]):_vm._e()])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }