/**
 * Add slash at the end of the url if missing.
 *
 * @param {string} url
 */
const slashIt = url => {
  if (url.substr(-1) != "/") {
    return (url += "/");
  }

  return url;
};

export const Config = {
  apiUrl: slashIt(process.env.VUE_APP_API_URL)
};
