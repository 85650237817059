import axios from "axios";

import { Config } from "@/helpers";

import {
  defaultErrorHandler,
  defaultUnauthorizedRequestHandler
} from "@/api/handlers";

axios.defaults.withCredentials = true;

axios.defaults.baseURL = `${Config.apiUrl}api/`;

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest"
};

/**
 * Default Error Interceptor.
 *
 * @param error
 * @returns {Promise<never>}
 */
function errorInterceptor(error) {
  if (error.response && 401 === error.response.status) {
    if (error.config.hasOwnProperty("unauthorizedHandler")) {
      error.config.unauthorizedHandler(error);
    } else {
      defaultUnauthorizedRequestHandler();
    }
  }

  if (error.config.hasOwnProperty("errorHandler")) {
    error.config.errorHandler(error);
  } else {
    defaultErrorHandler(error);
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(response => response, errorInterceptor);

/**
 * Intercept request and change config params based on request.
 *
 * The following changes are done.
 * - Don't prefix `api` to apiURL if `useBaseURL` is present.
 *
 * @param config
 * @returns {object}
 */
function requestInterceptor(config) {
  if (config.hasOwnProperty("useBaseURL") && config.useBaseURL === true) {
    config.baseURL = Config.apiUrl;
  }

  return config;
}

axios.interceptors.request.use(requestInterceptor, errorInterceptor);

export { axios as api };
