<template>
  <DoLoginViaEmail :mode="mode" />
</template>

<script>
import DoLoginViaEmail from "@/components/DoLoginViaEmail";

export default {
  name: "LoginViaEmailPage",
  components: {
    DoLoginViaEmail
  },
  props: {
    mode: String
  }
};
</script>

<style scoped></style>
