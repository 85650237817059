<template>
  <div class="home">
    <div class="no-group" v-if="!user.hasGroups">
      <h3>
        Welcome to Magiqspark.
      </h3>

      <p>
        You are not added to any group yet. Please
        <a
          href="https://wa.me/918838286014?text=Registered user not added to any group yet"
        >
          ask your mentors to add you to a group </a
        >.
      </p>

      <p>
        Meanwhile, you can checkout our
        <a href="https://hipoha.com">Hipoha website</a> or our
        <a href="https://www.youtube.com/channel/UCiQFbi9XfenVCRH_VygDhFA"
          >YouTube channel</a
        >.
      </p>
    </div>

    <van-tabs
      v-model="activeTab"
      sticky
      animated
      v-if="user.hasGroups"
      background="#896FBC"
      color="#fff"
      title-active-color="#420264"
      title-inactive-color="#ffffff"
      border="false"
    >
      <van-tab name="journals" title-style="font-weight:600">
        <div
          slot="title"
          :class="{ 'van-tab--active': activeTab === 'journals' }"
        >
          <van-icon name="sign" size="large" />Journals
        </div>

        <StepPage status="new" />
      </van-tab>

      <van-tab name="tasks" title-style="font-weight:600">
        <div slot="title" :class="{ 'van-tab--active': activeTab === 'tasks' }">
          <van-icon name="todo-list-o" size="large" />Tasks
        </div>

        <TaskPage status="new" />
      </van-tab>

      <van-tab name="nudges" title-style="font-weight:600">
        <div
          slot="title"
          :class="{ 'van-tab--active': activeTab === 'nudges' }"
        >
          <van-icon name="notes-o" size="large" /> Nudges
          <span v-if="unreadCount" class="unread-count">{{ unreadCount }}</span>
        </div>

        <NudgeList @unreadCountChanged="updateUnreadCount" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { NotificationProvider, ProfileProvider } from "@/providers";
import NudgeList from "@/components/NudgeList";
import TaskPage from "@/pages/TaskPage.vue";
import StepPage from "@/pages/StepPage.vue";

export default {
  name: "HomePage",
  components: {
    NudgeList,
    StepPage,
    TaskPage
  },
  data() {
    return {
      unreadCount: 0,
      activeTab: "journals"
    };
  },

  computed: {
    ...mapState("account", ["user"])
  },

  mounted() {
    if (!this.user.hasGroups) {
      // If the user doesn't have any groups, let's try to retrieve again.
      this.loadUser();
    }
    this.getNudgesUnreadCount();
  },

  methods: {
    loadUser() {
      const self = this;
      ProfileProvider.getProfile().then(response => {
        self.user.hasGroups = response.has_groups;
      });
    },
    getNudgesUnreadCount() {
      const self = this;
      NotificationProvider.getUnreadNotificationsCount().then(response => {
        self.unreadCount = response;
        if (self.unreadCount > 0) {
          self.activeTab = "nudges";
        }
      });
    },
    updateUnreadCount(count) {
      this.unreadCount = count;
    },
    setActiveTab(tabName) {
      this.activeTab = tabName;
    }
  }
};
</script>

<style scoped>
.van-tab .van-icon {
  margin-right: 3px;
  vertical-align: -4px;
}

.no-group {
  padding: 0 10% 10% 10%;
  text-align: left;
}

.unread-count {
  background-color: red;
  color: white;
  font-weight: bold;
  padding: 1px 6px;
  border-radius: 50%;
}

.van-tab .van-tab--active {
  font-weight: 600 !important;
  background-color: #ffffff !important;
}
</style>
