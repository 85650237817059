import { AuthProvider } from "@/providers";
import { defaultErrorHandler } from "@/api/handlers";

const user = AuthProvider.loggedInUser();

const state = user
  ? { status: { loggedIn: true }, user }
  : {
      status: {
        loggedIn: false,
        sentOTP: false,
        sendingOTPFailed: false,
        sendingOTPFailureIssue: null
      },
      user: null
    };

const actions = {
  async sendOTP({ commit }, { mobile }) {
    commit("sendOTPRequest", {});

    const unauthorizedHandler = error => {
      commit("sendOTPFailure", error.response.data);
    };

    const errorHandler = error => {
      commit("sendOTPFailure", error.response.data);

      defaultErrorHandler(error);
    };

    let user = await AuthProvider.sendOTP(
      { unauthorizedHandler, errorHandler },
      mobile
    );

    commit("sendOTPSuccess");

    return user;
  },

  async loginViaSMS({ commit }, { mobile, otp }) {
    commit("loginViaSMSRequest", {});

    const unauthorizedHandler = () => {
      commit("loginViaSMSFailure");
    };

    const user = await AuthProvider.loginViaSMS(
      { unauthorizedHandler },
      mobile,
      otp
    );

    commit("loginViaSMSSuccess", user);
  },

  async sendOTPEmail({ commit }, { email }) {
    commit("sendOTPRequest", {});

    const unauthorizedHandler = error => {
      commit("sendOTPFailure", error.response.data);
    };

    const errorHandler = error => {
      commit("sendOTPFailure", error.response.data);

      defaultErrorHandler(error);
    };

    let user = await AuthProvider.sendOTPEmail(
      { unauthorizedHandler, errorHandler },
      email
    );

    commit("sendOTPSuccess");

    return user;
  },

  async loginViaEmail({ commit }, { email, otp }) {
    commit("loginViaEmailRequest", {});

    const unauthorizedHandler = () => {
      commit("loginViaEmailFailure");
    };

    const user = await AuthProvider.loginViaEmail(
      { unauthorizedHandler },
      email,
      otp
    );

    commit("loginViaEmailSuccess", user);
  },

  logout({ commit }) {
    AuthProvider.logout();
    commit("logout");
  },

  async clearOTPFailureIssueState({ commit }) {
    commit("clearOTPFailureIssueState");
  },

  async getUserInfo({ commit }, { user }) {
    commit("setUserInfo", user);
  }
};

const mutations = {
  loginRequest(state) {
    state.status = { loggingIn: true };
  },

  loginSuccess(state, user) {
    state.status = { loggedIn: true, loggingIn: false };
    state.user = user;
  },

  loginFailure(state) {
    state.status = { loginFailed: true, loggingIn: false };
    state.user = null;
  },

  sendOTPRequest(state) {
    state.status = { ...state.status, sendingOTP: true };
  },

  sendOTPSuccess(state, user) {
    state.status = { sendingOTP: false, sentOTP: true };
    state.user = user;
  },

  sendOTPFailure(state, issue) {
    state.status = {
      sendingOTPFailed: true,
      sendingOTPFailureIssue: issue,
      sendingOTP: false
    };
    state.user = null;
  },

  clearOTPFailureIssueState(state) {
    state.status = { ...state.status, sendingOTPFailureIssue: null };
  },

  loginViaSMSRequest(state) {
    state.status = { sentOTP: true, loggingIn: true };
  },

  loginViaSMSSuccess(state, user) {
    state.status = { sentOTP: true, loggedIn: true };
    state.user = user;
  },

  loginViaSMSFailure(state) {
    state.status = { sentOTP: true, loginFailed: true };
    state.user = null;
  },

  loginViaEmailRequest(state) {
    state.status = { sentOTP: true, loggingIn: true };
  },

  loginViaEmailSuccess(state, user) {
    state.status = { sentOTP: true, loggedIn: true };
    state.user = user;
  },

  loginViaEmailFailure(state) {
    state.status = { sentOTP: true, loginFailed: true };
    state.user = null;
  },

  logout(state) {
    state.status = { loggedIn: false };
    state.user = null;
  },

  setUserInfo(state, user) {
    state.user = user;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
