import { api } from "@/api";

async function updateUserPreference(requestConfig = {}, params) {
  const response = await api.put(
    "/user/user-preference",
    {
      params
    },
    {
      ...requestConfig
    }
  );
  response.data;
}

export const UserPreferenceProvider = {
  updateUserPreference
};
