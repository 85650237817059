<template>
  <div class="home">
    <div class="no-group" v-if="!user.hasGroups">
      <h3>CJMs</h3>

      <p>
        You are not added to any group yet. Please
        <a
          href="https://wa.me/918838286014?text=Registered user not added to any group yet"
        >
          ask your mentors to add you to a group to get started </a
        >.
      </p>
    </div>

    <!-- <div v-if="user.hasGroups" class="newcjhighlevel"> -->
    <div v-if="user.hasGroups">
      <CustomerJourneyMapsHub
        v-if="user.hasGroups"
        :onCreateJourney="onCreateJourney"
        :onSelectJourney="onSelectJourney"
        :onEditJourney="onEditJourney"
        :onClose="onClose"
      />
    </div>
  </div>
</template>

<script>
import CustomerJourneyMapsHub from "@/components/CustomerJourneyMap/hub";

import { mapState } from "vuex";
import { ProfileProvider } from "@/providers";

export default {
  name: "CustomerJourneyMapsHubPage",
  components: {
    CustomerJourneyMapsHub
  },

  computed: {
    ...mapState("account", ["user"])
  },

  mounted() {
    if (!this.user.hasGroups) {
      // If the user doesn't have any groups, let's try to retrieve again.
      this.loadUser();
    }
  },

  methods: {
    loadUser() {
      const self = this;
      ProfileProvider.getProfile().then(response => {
        self.user.hasGroups = response.has_groups;
      });
    },
    onClose() {
      this.$router.push("/");
    },

    onCreateJourney() {
      this.$router.push("/customerjourneymaps/new");
    },
    onSelectJourney(journey) {
      this.$router.push("customerjourneymaps/" + journey.id);
    },
    onEditJourney(journey) {
      this.$router.push("customerjourneymaps/" + journey.id + "/edit");
    }
  }
};
</script>

<style scoped>
.van-tab .van-icon {
  margin-right: 3px;
  vertical-align: -4px;
}

.no-group {
  padding: 0 10% 10% 10%;
  text-align: left;
}
</style>
