import Vue from "vue";
import Vuex from "vuex";

import { account } from "@/store/modules/account";
import { profile } from "@/store/modules/profile";
import { steps } from "@/store/modules/steps";
import { preference } from "@/store/modules/preference";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    profile,
    steps,
    preference
  }
});
