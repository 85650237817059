<template>
  <!-- <div class="newcjhighlevel"> -->
  <div>
    <CustomerJourneyMapProblem
      v-if="mode == 'view'"
      :mode="mode"
      :journeyId="journeyId"
      :onJourneyCreated="onJourneyCreated"
      :onJourneyUpdated="onJourneyUpdated"
      :onJourneyFailedToLoad="onJourneyFailedToLoad"
      :onJourneyClosed="onJourneyClosed"
    />
    <CustomerJourneyMapProblemComposer
      v-if="mode != 'view'"
      :mode="mode"
      :journeyId="journeyId"
      :onJourneyCreated="onJourneyCreated"
      :onJourneyUpdated="onJourneyUpdated"
      :onJourneyFailedToLoad="onJourneyFailedToLoad"
      :onJourneyClosed="onJourneyClosed"
    />
  </div>
</template>

<script>
import { Dialog } from "vant";

import CustomerJourneyMapProblem from "@/components/CustomerJourneyMap/problem/index";
import CustomerJourneyMapProblemComposer from "@/components/CustomerJourneyMap/problem/composer";

export default {
  name: "CustomerJourneyMapProblemComposerPage",
  components: {
    CustomerJourneyMapProblem,
    CustomerJourneyMapProblemComposer
  },
  data() {
    return {
      journeyId: String,
      mode: String
    };
  },
  created() {
    this.journeyId = this.$route.params.id;
    let dest = this.$route.path.split("/").pop();
    this.mode = dest == this.journeyId ? "view" : dest;
  },

  methods: {
    onJourneyCreated() {
      this.$router.push("/customerjourneymaps");
    },

    onJourneyClosed() {
      this.$router.push("/customerjourneymaps");
    },

    onJourneyUpdated(journey) {
      window.location.assign("/customerjourneymaps/" + journey.id);
    },

    async onJourneyFailedToLoad() {
      await Dialog.alert({
        title: "Not exist",
        message: "Sorry, CJM not found. Please contact you Mentor",
        confirmButtonText: "Ok"
      });
      this.$router.push("/customerjounerymaps");
    }
  }
};
</script>
