<template>
  <form @submit.prevent="handleSave" class="cj-component">
    <div class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">
        1. Identity a Functional Area/ Scenario preferably in your scope of
        responsibility
      </h2>
      <div
        class="van-cell-group van-hairline--top-bottom van-doc-demo-block__card"
      >
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <input
                required
                type="text"
                v-model="functionalArea"
                placeholder="Functional Area"
                class="van-field__control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">2. Title Of Journey</h2>
      <div
        class="van-cell-group van-hairline--top-bottom van-doc-demo-block__card"
      >
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <input
                required
                type="text"
                v-model="title"
                placeholder="Title"
                class="van-field__control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">
        3. Identify the Stakeholder/Customer
      </h2>
      <div
        class="van-cell-group van-hairline--top-bottom van-doc-demo-block__card"
      >
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <input
                required
                type="text"
                v-model="customer"
                placeholder="Customer"
                class="van-field__control"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">
        4. Identify the Stakeholder/Customer Intention (Pick 2 from the List of
        Intentions below)
      </h2>
      <div
        class="van-cell-group van-hairline--top-bottom van-doc-demo-block__card"
      >
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <ul class="intention-list">
                <li
                  v-for="(intention, index) in allIntentions"
                  :key="index"
                  @click="onSelectIntention(intention)"
                  :class="intentionClass(intention)"
                >
                  {{ intention.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="intentionError" class="cj-error-text">
      *Please select any two options
    </p>
    <div v-if="isStepComposerReady" class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">
        5. Identify the steps of the journey
      </h2>
      <CustomerJourneyMapStepComposer
        :mode="mode"
        :journeySteps="this.steps"
        :onStepsChange="this.onStepsChange"
      />
    </div>
    <div class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">6. Extreme Objective</h2>
      <div
        class="van-cell-group van-hairline--top-bottom van-doc-demo-block__card"
      >
        <div class="van-cell van-field">
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <textarea
                type="text"
                v-model="extremeObjective"
                placeholder="Extreme Objective"
                class="van-field__control"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 16px">
      <van-button round block type="submit" native-type="submit"
        >Complete</van-button
      >
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        @click="handleClose"
        >Close</van-button
      >
    </div>
  </form>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Button,
  NavBar,
  DropdownMenu,
  DropdownItem
} from "vant";
import { Step, Steps } from "vant";
import { Field } from "vant";

import { Journey } from "@/entity/CustomerJourney/journey";

import { CustomerJourneyProvider } from "@/providers/CustomerJourneyProvider";

import CustomerJourneyMapStepComposer from "@/components/CustomerJourneyMap/step/composer/CustomerJourneyMapStepComposer.vue";

export default {
  name: "CustomerJourneyMapProblemComposer",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,

    CustomerJourneyMapStepComposer
  },

  props: {
    mode: String,
    journeyId: String,
    onJourneyCreated: Function,
    onJourneyUpdated: Function,
    onJourneyFailedToLoad: Function,
    onJourneyClosed: Function
  },

  data() {
    return {
      journey: null,
      isStepComposerReady: false,

      id: null,
      functionalArea: null,
      title: null,
      customer: null,
      intentions: [],
      extremeObjective: null,
      steps: [],

      allIntentions: [],
      showIntention1Picker: false,
      showIntention2Picker: false,
      intentionError: false
    };
  },

  async created() {
    if (this.journeyId) {
      this.journey = await new CustomerJourneyProvider().journeyWithId(
        this.journeyId
      );
      if (this.journey) {
        this.loadJourney();
      } else {
        this.onJourneyFailedToLoad();
      }
    }
    this.isStepComposerReady = true;
    this.allIntentions = await new CustomerJourneyProvider().intentions();
  },

  methods: {
    loadJourney() {
      this.id = this.journey.id;
      this.functionalArea = this.journey.functionalArea;
      this.title = this.journey.title;
      this.customer = this.journey.customer;
      this.intentions = this.journey.intentions;

      this.intentions.splice(0);
      this.allIntentions.forEach(intention => {
        this.intentions.push(intention);
      });

      this.steps = this.journey.steps;
      this.extremeObjective = this.journey.extremeObjective;
    },

    handleClose() {
      this.onJourneyClosed();
    },

    async handleSave() {
      if (this.intentions.length < 2) {
        this.intentionError = true;
        return false;
      }
      let cleanSteps = this.steps.filter(step => {
        return !step.isComposing;
      });
      let journey = new Journey(
        this.id,
        this.functionalArea,
        this.title,
        this.customer,
        this.intentions,
        cleanSteps,
        this.extremeObjective
      );
      switch (this.mode) {
        case "new":
          await new CustomerJourneyProvider().createJourney(journey);
          this.onJourneyCreated();
          break;
        case "edit":
          this.journey = await new CustomerJourneyProvider().updateJourney(
            journey
          );
          this.onJourneyUpdated(journey);
          break;
        default:
          break;
      }
    },
    onStepsChange(steps) {
      this.steps = steps;
    },

    onSelectIntention(intention) {
      this.intentionError = false;
      const index = this.intentions.findIndex(
        anIntention => anIntention.id == intention.id
      );
      if (index >= 0) {
        this.intentions.splice(index, 1);
      } else {
        this.intentions.push(intention);
        if (this.intentions.length > 2) {
          this.intentions.splice(0, 1);
        }
      }
    },
    intentionClass(intention) {
      const isSelected =
        this.intentions.findIndex(
          anIntention => anIntention.title == intention.title
        ) >= 0;
      return {
        active: isSelected,
        normal: !isSelected
      };
    }
  }
};
</script>

<style src="@/assets/customerjourney/styles/vantdemo.css" />
