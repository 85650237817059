<template>
  <div class="cj-component stage-two">
    <div class="list-wrapper">
      <div class="red-line"></div>
      <div v-for="step in steps" v-bind:key="step.title">
        <div
          v-if="!step.isEditing && !step.isComposing"
          :class="
            'journey-card left animate-top' +
              (step.isTrimTab() ? ' trim-tab' : '')
          "
        >
          <!-- <div class="list-bullet big-bullet"> -->
          <div
            :class="
              'list-bullet big-bullet ' +
                (step.impacts.length == 3
                  ? cumulativeScoreOfStep(step).color
                  : 'black') +
                '-bullet'
            "
          >
            <img
              :src="
                require('@/assets/customerjourney/white-icon/' +
                  (step.impacts.length == 3
                    ? cumulativeScoreOfStep(step).id
                    : 'default') +
                  '.svg')
              "
              alt
            />
          </div>
          <div class="list-item compl-list">
            <div class="fill-order-one">
              <div class="content-area">
                <div v-if="step.isTrimTab()" class="chip">
                  <span>Trim Tab</span>
                </div>
                <h4>{{ step.title }}</h4>
                <h6>{{ step.description }}</h6>
                <h6>{{ step.comment }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";

import { ImpactKinds } from "@/entity/CustomerJourney/impactkind";
import { ImpactScore } from "@/entity/CustomerJourney/impactscore";
import { JourneyStep } from "@/entity/CustomerJourney/journeystep";

export default {
  name: "CustomerJourneyMapStep",
  components: {
    [Button.name]: Button
  },
  props: {
    mode: String,
    journeySteps: Array,
    onStepsChange: Function
  },
  data() {
    return {
      drag: false,
      impactKinds: [
        ImpactKinds.NegativeEmotion,
        ImpactKinds.HightTAT,
        ImpactKinds.OppossesIntention
      ],
      impactScores: [
        new ImpactScore("no", 0, "No", "green"),
        new ImpactScore("bad", 1, "Bad", "yellow"),
        new ImpactScore("worse", 2, "Worse", "orange"),
        new ImpactScore("worst", 3, "Worst", "red")
      ],
      steps: [],

      order: null,
      title: null,
      description: null,
      comment: null,
      impacts: [],

      isPopoverVisible: false,
      popoverOptions: {
        animation: "scale-fade",
        popoverReference: null,
        placement: "top-left",
        offset: "0 , 0"
      },
      composingStep: null
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  created() {
    switch (this.mode) {
      case "new":
        this.initializeANewJourneyStep();
        break;
      case "view":
        this.steps = this.journeySteps;
        break;
      case "edit":
        this.steps = this.journeySteps;
        this.initializeANewJourneyStep();
        break;
      default:
        break;
    }
  },

  mounted() {
    this.popoverOptions.popoverReference = this.$refs.popoverReference;
  },

  methods: {
    cumulativeScoreOfStep(step) {
      let scoreValue = Math.round(step.score());
      let score = this.impactScores.find(score => {
        return score.score == scoreValue;
      });
      return score;
    },

    initializeANewJourneyStep() {
      this.order = this.steps.length + 1;
      this.steps.splice(this.steps.length, 0, this.aNewJourneyStep());
      this.impacts.splice(0, 3);
    },
    aNewJourneyStep() {
      let step = new JourneyStep(this.steps.length + 1);
      step.isComposing = true;
      return step;
    }
  }
};
</script>

<style src="@/assets/customerjourney/styles/stage.css" />
