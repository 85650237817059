import Vue from "vue";

window.Event = new class {
  // TODO: create constants for each event that is getting triggered.

  constructor() {
    this.vue = new Vue();
  }

  trigger(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(event, callback) {
    this.vue.$on(event, callback);
  }
}();
