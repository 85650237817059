import { UserPreferenceProvider } from "@/providers";

const state = {
  status: { updating: false, updateFailed: false, updated: false }
};

const actions = {
  async updatePreference({ commit }, { params }) {
    commit("updateRequest", {});
    const unexpectedHandler = () => {
      commit("updateFailure");
    };
    await UserPreferenceProvider.updateUserPreference(
      { unexpectedHandler },
      params
    );
    commit("updateSuccess");
  }
};

const mutations = {
  updateRequest(state) {
    state.status = { updated: false, updating: true };
  },

  updateSuccess(state) {
    state.status = { updated: true, updating: false };
  },

  updateFailure(state) {
    state.status = { updated: false, updating: false, updateFailed: true };
  }
};

export const preference = {
  namespaced: true,
  state,
  actions,
  mutations
};
