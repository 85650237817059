<template>
  <div id="login">
    <Logo />

    <van-loading type="spinner" color="#f44" v-if="sendingOTP" />

    <van-row>
      <van-col span="20" offset="2">
        <van-row type="flex" justify="space-around" align="center">
          <van-field
            v-model="email"
            type="email"
            placeholder="Enter your email to login"
            maxlength="60"
            size="large"
            :error-message="emailFieldError"
            border
          />
        </van-row>
      </van-col>
    </van-row>

    <van-row>
      <van-col span="20" offset="2" align="right">
        <van-button
          type="primary"
          round
          :text="sendingOTP ? 'Sending OTP' : 'Get OTP'"
          size="large"
          :disabled="sendingOTP"
          @click="handleGetOTP"
        />
      </van-col>
    </van-row>

    <a class="sms-login" href="" @click="gotoSMSLogin">
      Login using mobile number
    </a>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import { mapState, mapActions } from "vuex";
import { Login, Url } from "@/helpers";

import { router } from "@/router";
import { Dialog } from "vant";

export default {
  name: "DoLoginViaEmail",
  components: { Logo },

  data() {
    return {
      email: null,

      isLogout: false,

      nativeBridge: null,
      isNativeBridgeObtained: false,

      emailFieldError: null
    };
  },

  computed: {
    ...mapState({
      sendingOTP: state => state.account.status.sendingOTP,
      sendingOTPFailureIssue: state =>
        state.account.status.sendingOTPFailureIssue
    }),

    ...mapState("account", ["user"])
  },

  watch: {
    sendingOTPFailureIssue(issue) {
      if (!issue) {
        return;
      }

      if (issue.user && issue.user.exists === false) {
        Dialog.confirm({
          title: "User not found",
          message:
            "You don't seem to have registered using this Email before. Do you want to try a different Email?",
          confirmButtonText: "Use a different Email",
          cancelButtonText: "Cancel"
        })
          .then(async () => {
            this.email = null;
            await this.clearOTPFailureIssueState();
          })
          .catch(async () => {
            router.push({
              name: "login-via-email-page",
              params: {
                email: this.email
              }
            });
          });
      }
    },

    isLogout(isLogout) {
      if (!isLogout) {
        return;
      }

      const nb = window.nsWebViewBridge;
      if (nb) {
        nb.emit("logout", null);
      }
    },

    async email(value) {
      if (!value) {
        return;
      }

      if (value.length > 0) {
        this.emailFieldError = "";
      }
    }
  },

  created() {
    const isLogout = Url.getParam("logout");

    if (isLogout) {
      this.isLogout = true;
    }

    window.addEventListener("ns-bridge-ready", e => {
      const nsWebViewBridge = e.detail || window.nsWebViewBridge;
      if (nsWebViewBridge) {
        this.isNativeBridgeObtained = true;
        this.nativeBridge = nsWebViewBridge;
      }
    });

    this.logout();
  },

  methods: {
    ...mapActions("account", ["sendOTPEmail", "logout"]),

    gotoSMSLogin: Login.gotoSMSLogin,

    async handleGetOTP() {
      if (!this.email || this.email.length === 0) {
        this.emailFieldError = "Email required";

        return;
      }

      if (!Login.isValidEmail(this.email)) {
        this.emailFieldError = "Invalid email";

        return;
      }

      await this.sendOTPEmail({
        email: this.email
      });

      router.push({
        name: "verify-email-otp",
        params: {
          email: this.email,
          appId: Login.getAppId()
        }
      });
    }
  }
};
</script>

<style scoped>
.van-row {
  margin-bottom: 50px;
}

.sms-login {
  color: #07c160;
}

.sms-login:hover {
  text-decoration: underline;
}

.van-row input {
  border-radius: 10em;
  height: 50px;
  line-height: 48px;
  padding-left: 10px;
  width: 97%;
}

.van-loading {
  z-index: 999;
}
</style>
