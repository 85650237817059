<template>
  <van-cell :border="true" size="large" v-on:click="onClickJournal">
    <template #default>
      <strong>
        <span v-html="task.name" />
      </strong>
      <span v-if="task.title">
        <br />
        <span v-html="task.title" />
      </span>
    </template>
  </van-cell>
</template>

<script>
export default {
  name: "TaskListItem",
  props: {
    task: Object
  },

  data() {
    return {
      taskJournaled: false
    };
  },

  created() {
    if (this.task.status === "completed") {
      this.taskJournaled = true;
    }
  },

  methods: {
    onClickJournal() {
      if (!this.taskJournaled) {
        this.$router.push({
          name: "task-journal-page",
          params: {
            taskId: this.task.id,
            task: this.task
          }
        });
      }
    }
  }
};
</script>

<style scoped></style>
