/**
 * Helpers for working with the currently logged in user.
 */

/**
 * The local storage key.
 *
 * @type {string}
 */
const key = "sparker-user";

/**
 * Get the current user.
 *
 * @returns {object}
 */
function get() {
  return JSON.parse(localStorage.getItem(key));
}

/**
 * Set the current user.
 *
 * @param Object user
 */
function set(user) {
  localStorage.setItem(key, JSON.stringify(user));
}

/**
 * Clear the current user.
 */
function clear() {
  localStorage.removeItem(key);
}

/**
 * Is the user logged in?
 *
 * @returns {boolean}
 */
function loggedIn() {
  const user = get();

  return user !== null;
}

export const CurrentUser = {
  get,
  set,
  clear,
  loggedIn
};
