<template>
  <div>
    <div class="van-tabs">
      <NudgeListItem
        v-for="(item, index) in notifications"
        :key="index"
        :content="item"
        @markAsRead="markAsRead(index)"
      />
    </div>
    <div v-if="notifications.length === 0" class="empty-notification">
      <van-cell :border="true" size="large">
        <p class="no_item">No nudges to display.</p>
      </van-cell>
    </div>
  </div>
</template>

<script>
import NudgeListItem from "./NudgeListItem.vue";
import { NotificationProvider } from "@/providers";

export default {
  components: {
    NudgeListItem
  },

  data() {
    return {
      notifications: {},
      unreadCount: 0
    };
  },

  mounted() {
    this.fetchNudges();
  },

  methods: {
    fetchNudges() {
      const self = this;
      NotificationProvider.getNotifications().then(response => {
        self.notificationsLoaded = true;
        self.notifications = response;
      });
    },
    markAsRead(index) {
      this.notifications[index].status.read_status = !this.notifications[index]
        .status.read_status;
      const unreadCount = this.notifications.filter(
        notification =>
          notification.status && notification.status.read_status === "unread"
      ).length;
      this.$emit("unreadCountChanged", unreadCount);
    }
  }
};
</script>

<style scoped>
.no_item {
  text-align: center;
}
.van-tabs {
  margin: 5px 0px !important;
}
</style>
