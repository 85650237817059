import { api } from "@/api";

import { Journey } from "../entity/CustomerJourney/journey";
import { Intention } from "../entity/CustomerJourney/intention";
import { ImpactKind } from "../entity/CustomerJourney/impactkind";
import { ImpactScore } from "../entity/CustomerJourney/impactscore";
import { Impact } from "../entity/CustomerJourney/impact";
import { JourneyStep } from "../entity/CustomerJourney/journeystep";

export class CustomerJourneyProvider {
  async intentions() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await api.get("/customer-journey/customerintentions");
        let customerIntentionsInfos = response.data;
        let customerIntentions = [];
        customerIntentionsInfos.forEach(customerIntentionInfo => {
          customerIntentions.push(
            new Intention(
              customerIntentionInfo.id,
              customerIntentionInfo.title,
              customerIntentionInfo.description
            )
          );
        });
        resolve(customerIntentions);
      } catch (error) {
        reject(error);
      }
    });
  }

  async impactKinds() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await api.get("/customer-journey/impact-kinds");
        let impactKindsInfos = response.data;
        let impactKinds = [];
        impactKindsInfos.forEach(impactKindInfo => {
          impactKinds.push(
            new ImpactKind(impactKindInfo.id, impactKindInfo.name)
          );
        });
        resolve(impactKinds);
      } catch (error) {
        reject(error);
      }
    });
  }

  async journeyWithId(id) {
    return new Promise(async (resolve, reject) => {
      try {
        let journey = null;
        if (id == "example") {
          journey = await this.exampleJourney();
          resolve(journey);
        } else {
          let response = await api.get(
            "/customer-journey/customer-journeys/" + id
          );
          let journeyInfo = response.data;
          let journey = this.journeyOfJourneyInfo(journeyInfo);
          resolve(journey);
        }
      } catch (error) {
        if (error.toString().indexOf("404") >= 0) {
          resolve(null);
          return;
        } else {
          reject(error);
        }
      }
    });
  }

  journeys() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await api.get("/customer-journey/customer-journeys");
        let journeysInfos = response.data;
        let journeys = this.journeysOfJourneysInfos(journeysInfos);
        resolve(journeys);
      } catch (error) {
        reject(error);
      }
    });
  }

  createJourney(journey) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.post(
          "/customer-journey/customer-journeys",
          this.journeyInfoOfJourney(journey)
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  updateJourney(journey) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.put(
          "/customer-journey/customer-journeys/" + journey.id,
          this.journeyInfoOfJourney(journey)
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  deleteJourney(journey) {
    return new Promise(async (resolve, reject) => {
      try {
        await api.delete("/customer-journey/customer-journeys/" + journey.id);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  journeyInfoOfJourney(journey) {
    let journeyInfo = {
      title: journey.title,
      functionalArea: journey.functionalArea,
      customer: journey.customer,
      intentions: journey.intentions,
      steps: this.stepsInfosOfSteps(journey.steps),
      extremeObjective: journey.extremeObjective
    };
    if (journey.id) {
      journeyInfo = {
        ...journeyInfo,
        id: journey.id
      };
    }
    return journeyInfo;
  }

  stepsInfosOfSteps(steps) {
    let stepsInfos = [];
    steps.forEach(step => {
      stepsInfos.push(this.stepInfoOfStep(step));
    });
    return stepsInfos;
  }

  stepInfoOfStep(step) {
    let stepInfo = {
      order: step.order,
      title: step.title,
      description: step.description,
      impacts: this.impactsInfosOfImpacts(step.impacts),
      comment: step.comment
    };
    if (step.id) {
      stepInfo = {
        ...stepInfo,
        id: step.id
      };
    }
    return stepInfo;
  }

  impactsInfosOfImpacts(impacts) {
    let impactsInfos = [];
    impacts.forEach(impact => {
      impactsInfos.push(this.impactInfoOfImpact(impact));
    });
    return impactsInfos;
  }

  impactInfoOfImpact(impact) {
    let impactInfo = {
      kind: impact.kind,
      score: this.impactScoreInfoOfImpactScore(impact.score)
    };
    if (impact.id) {
      impactInfo = {
        ...impactInfo,
        id: impact.id
      };
    }
    return impactInfo;
  }

  impactScoreInfoOfImpactScore(impactScore) {
    let impactScoreInfo = {
      id: impactScore.id,
      score: impactScore.score,
      description: impactScore.description
    };
    return impactScoreInfo;
  }

  impactsOfImpactsInfos(impactsInfos) {
    let impacts = [];
    if (impactsInfos) {
      impactsInfos.forEach(impactInfo => {
        impacts.push(this.impactOfImpactInfo(impactInfo));
      });
    }
    return impacts;
  }

  impactOfImpactInfo(impactInfo) {
    return new Impact(
      impactInfo.kind,
      this.impactScoreOfImpactScoreInfo(impactInfo.score)
    );
  }

  impactScoreOfImpactScoreInfo(impactScoreInfo) {
    let impactScore = new ImpactScore(
      impactScoreInfo.id,
      impactScoreInfo.score,
      impactScoreInfo.description,
      impactScoreInfo.color
    );
    return impactScore;
  }

  journeyOfJourneyInfo(journeyInfo) {
    return new Journey(
      journeyInfo.id,
      journeyInfo.functional_area,
      journeyInfo.title,
      journeyInfo.customer,
      journeyInfo.intentions,
      this.stepsOfStepsInfos(journeyInfo.steps),
      journeyInfo.extreme_objective
    );
  }

  stepsOfStepsInfos(stepsInfos) {
    let steps = [];
    if (stepsInfos) {
      stepsInfos.forEach(stepInfo => {
        steps.push(this.stepOfStepInfo(stepInfo));
      });
    }
    return steps;
  }

  stepOfStepInfo(stepInfo) {
    return new JourneyStep(
      stepInfo.order,
      stepInfo.title,
      stepInfo.description,
      this.impactsOfImpactsInfos(stepInfo.impacts),
      stepInfo.comment
    );
  }

  journeysOfJourneysInfos(journeysInfos) {
    let journeys = [];
    journeysInfos.forEach(journeyInfo => {
      journeys.push(this.journeyOfJourneyInfo(journeyInfo));
    });
    return journeys;
  }
}
