<template>
  <div class="cj-component">
    <div class="view-card">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">Functional Area/ Scenario</h2>
        <span>{{ functionalArea }}</span>
      </div>
    </div>
    <div class="view-card">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">Title</h2>
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="view-card">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">Stakeholder/Customer</h2>
        <span>{{ customer }}</span>
      </div>
    </div>
    <div class="view-card">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">
          Stakeholder/Customer Intention
        </h2>
        <span>
          <ul class="intention-list">
            <li
              v-for="(intention, index) in intentions"
              :key="index"
              class="normal"
            >
              {{ intention.title }}
            </li>
          </ul></span
        >
      </div>
    </div>
    <div v-if="isStepComposerReady" class="van-doc-demo-block">
      <h2 class="van-doc-demo-block__title">Steps</h2>
      <CustomerJourneyMapStep
        :mode="mode"
        :journeySteps="this.steps"
        :onStepsChange="this.onStepsChange"
      />
    </div>
    <div class="view-card">
      <div class="van-doc-demo-block">
        <h2 class="van-doc-demo-block__title">Extreme Objective</h2>
        <span>{{ extremeObjective }}</span>
      </div>
    </div>
    <div style="margin: 16px">
      <van-button
        round
        block
        type="primary"
        native-type="submit"
        @click="handleClose"
        >Close</van-button
      >
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Button,
  NavBar,
  DropdownMenu,
  DropdownItem
} from "vant";
import { Step, Steps } from "vant";
import { Field } from "vant";

import { CustomerJourneyProvider } from "@/providers/CustomerJourneyProvider";

import CustomerJourneyMapStep from "../step/CustomerJourneyMapStep.vue";

export default {
  name: "CustomerJourneyMapProblem",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,

    CustomerJourneyMapStep
  },

  props: {
    mode: String,
    journeyId: String,
    onJourneyCreated: Function,
    onJourneyUpdated: Function,
    onJourneyFailedToLoad: Function,
    onJourneyClosed: Function
  },

  data() {
    return {
      journey: null,
      isStepComposerReady: false,

      id: null,
      functionalArea: null,
      title: null,
      customer: null,
      selectedIntentions: [],
      intentions: [],
      extremeObjective: null,
      steps: []
    };
  },

  async created() {
    if (this.journeyId) {
      this.journey = await new CustomerJourneyProvider().journeyWithId(
        this.journeyId
      );
      if (this.journey) {
        this.loadJourney();
      } else {
        this.onJourneyFailedToLoad();
      }
    }
    this.isStepComposerReady = true;
  },

  methods: {
    loadJourney() {
      this.id = this.journey.id;
      this.functionalArea = this.journey.functionalArea;
      this.title = this.journey.title;
      this.customer = this.journey.customer;
      this.intentions = this.journey.intentions;

      this.selectedIntentions.splice(0);
      this.intentions.forEach(intention => {
        this.selectedIntentions.push(intention);
      });

      this.steps = this.journey.steps;
      this.extremeObjective = this.journey.extremeObjective;
    },

    handleClose() {
      this.onJourneyClosed();
    },

    onStepsChange(steps) {
      this.steps = steps;
    }
  }
};
</script>

<style src="@/assets/customerjourney/styles/vantdemo.css" />
