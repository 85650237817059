<template>
  <VerifyEmailOtp />
</template>

<script>
import VerifyEmailOtp from "@/components/VerifyEmailOtp";

export default {
  name: "VerifyEmailOtpPage",
  components: {
    VerifyEmailOtp
  }
};
</script>

<style scoped></style>
