<template>
  <van-cell :border="true" size="large" v-on:click="onClickJournal">
    <template #default>
      <strong>
        <span v-html="step.display_name" />
      </strong>
      <br />
      <span v-html="step.routine.routine_template.name" />
    </template>
  </van-cell>
</template>

<script>
export default {
  name: "StepListItem",
  props: {
    step: Object
  },

  data() {
    return {
      stepJournaled: false
    };
  },

  created() {
    if (this.step.status === "completed") {
      this.stepJournaled = true;
    }
  },

  methods: {
    onClickJournal() {
      this.$router.push({
        name: "journal-page",
        params: {
          stepId: this.step.id,
          step: this.step
        }
      });
    }
  }
};
</script>

<style scoped></style>
