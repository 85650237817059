import { api } from "@/api";

/**
 * Get user group settings.
 *
 * @returns {AxiosPromise<any>}
 */
async function getReportUrl() {
  return api.get(`group-settings/report-url/`);
}

export const GroupSettingProvider = {
  getReportUrl
};
