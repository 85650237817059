import { api } from "@/api";
import { CurrentUser } from "@/helpers";

/**
 * Get the currently logged in user.
 *
 * @returns {any}
 */
function loggedInUser() {
  return CurrentUser.get();
}

function loginUser(user) {
  let userObject = {};
  userObject.id = user.id;
  userObject.name = user.name;
  userObject.displayName = user.display_name;
  userObject.hasGroups = user.has_groups;
  userObject.accessToken = user.accessToken;

  CurrentUser.set(userObject);

  return userObject;
}

async function sendOTP(requestConfig = {}, mobile) {
  await api.get("auth/csrf-cookie", {
    useBaseURL: true,
    ...requestConfig
  });

  let url = "auth/sms/login";

  const response = await api.post(
    url,
    {
      mobile
    },
    {
      useBaseURL: true,
      ...requestConfig
    }
  );
  return response.data;
}

async function loginViaSMS(requestConfig = {}, mobile, otp) {
  await api.get("auth/csrf-cookie", {
    useBaseURL: true,
    ...requestConfig
  });

  let url = "auth/sms/verify";
  const response = await api.post(
    url,
    {
      mobile,
      otp
    },
    {
      useBaseURL: true,
      ...requestConfig
    }
  );

  const user = response.data;
  if (!user) {
    return {};
  }

  return loginUser(user);
}

async function sendOTPEmail(requestConfig = {}, email) {
  await api.get("auth/csrf-cookie", {
    useBaseURL: true,
    ...requestConfig
  });

  let url = "auth/email/login";

  const response = await api.post(
    url,
    {
      email
    },
    {
      useBaseURL: true,
      ...requestConfig
    }
  );
  return response.data;
}
async function loginViaEmail(requestConfig = {}, email, otp) {
  await api.get("auth/csrf-cookie", {
    useBaseURL: true,
    ...requestConfig
  });

  let url = "auth/email/verify";
  const response = await api.post(
    url,
    {
      email,
      otp
    },
    {
      useBaseURL: true,
      ...requestConfig
    }
  );

  const user = response.data;
  if (!user) {
    return {};
  }

  return loginUser(user);
}

function logout() {
  CurrentUser.clear();

  api.get("auth/email/logout", {
    useBaseURL: true
  });
}

/**
 * Get Magiqspark Redirect Url.
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
async function getMagiqsparkRedirectUrl() {
  return api.get(`user/apps/magiqspark/redirect/`);
}

export const AuthProvider = {
  loggedInUser,
  sendOTP,
  loginViaSMS,
  logout,
  sendOTPEmail,
  loginViaEmail,
  getMagiqsparkRedirectUrl
};
