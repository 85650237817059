function getParam(key) {
  const uri = window.location.search.substring(1);
  const params = new URLSearchParams(uri);

  return params.get(key);
}

export const Url = {
  getParam
};
