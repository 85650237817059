<template>
  <div class="cj-component stage-two">
    <div class="list-wrapper">
      <div class="red-line"></div>
      <div v-for="(step, stepIndex) in steps" v-bind:key="step.title">
        <!-- JOURNEY CARD COMPLETED -->
        <div
          v-if="!step.isEditing && !step.isComposing"
          :class="
            'journey-card left animate-top' +
              (step.isTrimTab() ? ' trim-tab' : '')
          "
        >
          <div
            :class="
              'list-bullet big-bullet ' +
                (step.impacts.length == 3
                  ? cumulativeScoreOfStep(step).color
                  : 'black') +
                '-bullet'
            "
          >
            <img
              :src="
                require('@/assets/customerjourney/white-icon/' +
                  (step.impacts.length == 3
                    ? cumulativeScoreOfStep(step).id
                    : 'default') +
                  '.svg')
              "
              alt
            />
          </div>
          <div class="list-item">
            <!-- BUtton area -->
            <div class="button-area new-icon-area">
              <!-- Trim tab -->
              <div v-if="step.isTrimTab()" class="chip">
                <span>Trim Tab</span>
              </div>
              <button
                v-if="stepIndex > 0"
                class="up-icon"
                @click="moveStepUpward(step)"
              >
                <img :src="require('@/assets/customerjourney/up.svg')" alt />
              </button>
              <button
                v-if="stepIndex < steps.length - 1"
                class="down-icon"
                @click="moveStepDownward(step)"
              >
                <img :src="require('@/assets/customerjourney/down.svg')" alt />
              </button>
              <button
                class="more-icon"
                ref="menu"
                @click="toggleStepMorePopoverState(stepIndex)"
              >
                <img :src="require('@/assets/customerjourney/more.svg')" alt />
              </button>

              <!-- Dropdown menu -->
              <section
                class="dropdownMenu"
                v-if="stepsMorePopverState[stepIndex]"
              >
                <ul>
                  <li
                    v-if="!step.isEditing && !step.isComposing"
                    class="save-button"
                    @click="onBeginEditing(step)"
                  >
                    <i class="van-icon van-icon-edit edit-icon" />
                    Edit
                  </li>
                  <li
                    v-if="!step.isEditing && !step.isComposing"
                    class="delete-button"
                    @click="onDelete(step)"
                  >
                    <i class="van-icon van-icon-delete edit-icon" />
                    Delete
                  </li>
                  <li
                    v-if="step.isEditing"
                    class="save-button"
                    @click="onSave(step)"
                  >
                    <i class="van-icon van-icon-completed edit-icon" />
                    save
                  </li>
                </ul>
              </section>
            </div>

            <!-- Content -->
            <div class="compl-list">
              <div class="fill-order-one">
                <div class="content-area">
                  <h4>{{ step.title }}</h4>
                  <h6>{{ step.description }}</h6>
                  <h6>{{ step.comment }}</h6>
                </div>
              </div>
              <div class="fill-order-two">
                <div class="fill-feedback-area">
                  <span class="line"></span>
                  <ul>
                    <li
                      v-for="(impactKind, index) in impactKinds"
                      v-bind:key="index"
                      class="over-icon"
                    >
                      <div
                        :class="
                          'circle-area ' +
                            step.impacts[index].score.color +
                            '-circle'
                        "
                      >
                        <img
                          :src="
                            require('@/assets/customerjourney/white-icon/' +
                              step.impacts[index].score.id +
                              '.svg')
                          "
                          alt
                        />
                      </div>
                      <h6>{{ impactKind.name }}</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- JOURNEY CARD INITIALIZE -->
        <div
          v-if="step.isEditing || step.isComposing"
          class="journey-card animate-bottom"
        >
          <div class="list-bullet">
            <div class="small-bullet">
              <img
                :src="
                  require('@/assets/customerjourney/white-icon/default.svg')
                "
                alt
              />
            </div>
          </div>
          <div class="list-item">
            <form
              @submit.prevent="step.isComposing ? onSave() : onSave(step)"
              class="order-one"
            >
              <div class="field-area">
                <!-- TITLE INPUT FIELD -->
                <div class="form__group1 field">
                  <textarea
                    v-model="title"
                    rows="1"
                    maxlength="40"
                    class="form__field1"
                    placeholder="Name"
                    name="name"
                    id="name"
                    required
                  />
                  <label for="name" class="form__label1">Title</label>
                </div>
                <!-- SUB TITLE INPUT FIELD -->
                <div class="form__group field">
                  <input
                    v-model="description"
                    type="input"
                    class="form__field"
                    placeholder="sub"
                    name="sub"
                    id="sub"
                  />
                  <label for="sub" class="form__label">Sub title</label>
                </div>
                <!-- COMMENT INPUT FIELD -->
                <div class="form__group1 form_group3 field">
                  <textarea
                    v-model="comment"
                    rows="1"
                    maxlength="40"
                    class="form__field1"
                    placeholder="Name"
                    name="name"
                    id="name"
                  />
                  <label for="name" class="form__label1">Comment</label>
                </div>
              </div>
              <div class="button-area">
                <button class="save-button" type="submit">
                  <i class="van-icon van-icon-passed edit-icon" />
                </button>
              </div>
            </form>
            <div class="order-two">
              <div class="feedback-area">
                <span class="line"></span>
                <ul>
                  <li
                    v-for="(impactKind, index) in impactKinds"
                    v-bind:key="index"
                    class="over-icon"
                    ref="impacts"
                  >
                    <popover
                      :pointer="false"
                      :name="step.order.toString() + impactKind.name"
                    >
                      <div class="popover-area">
                        <h6>Impact</h6>
                        <ul>
                          <li
                            v-for="impactScore in impactScores"
                            v-bind:key="impactScore.id"
                            @click="
                              onSelectScoreOnImpactKind(impactScore, impactKind)
                            "
                          >
                            <img
                              :src="
                                require('@/assets/customerjourney/gray-icon/' +
                                  impactScore.id +
                                  '.svg')
                              "
                              alt
                            />
                            <span>{{ impactScore.description }}</span>
                          </li>
                        </ul>
                      </div>
                    </popover>
                    <div
                      v-popover.top="{
                        name: step.order.toString() + impactKind.name
                      }"
                    >
                      <div
                        :class="
                          'circle-area ' +
                            (impacts.length > index
                              ? impacts[index].score.color + '-circle'
                              : impacts.length == index
                              ? 'current-circle'
                              : '')
                        "
                      >
                        <img
                          :src="
                            require('@/assets/customerjourney/white-icon/' +
                              (impacts.length > index
                                ? impacts[index].score.id
                                : 'default') +
                              '.svg')
                          "
                          alt
                        />
                      </div>
                    </div>

                    <h6>
                      {{ impactKind.name }}
                    </h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 38px">
        <van-button
          round
          block
          type="secondary"
          class="add-btn"
          @click="initializeANewJourneyStep()"
          >Add a step</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { Dialog } from "vant";

import { ImpactScore } from "@/entity/CustomerJourney/impactscore";
import { Impact } from "@/entity/CustomerJourney/impact";
import { JourneyStep } from "@/entity/CustomerJourney/journeystep";
import { CustomerJourneyProvider } from "@/providers/CustomerJourneyProvider";

export default {
  name: "CustomerJourneyMapStepComposer",
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog
  },
  props: {
    mode: String,
    journeySteps: Array,
    onStepsChange: Function
  },
  data() {
    return {
      drag: false,
      stepsMorePopverState: [],
      impactKinds: [],
      impactScores: [
        new ImpactScore("no", 0, "No", "green"),
        new ImpactScore("bad", 1, "Bad", "yellow"),
        new ImpactScore("worse", 2, "Worse", "orange"),
        new ImpactScore("worst", 3, "Worst", "red")
      ],
      steps: [],

      order: null,
      title: null,
      description: null,
      comment: null,
      impacts: [],

      isPopoverVisible: false,
      popoverOptions: {
        animation: "scale-fade",
        popoverReference: null,
        placement: "top-left",
        offset: "0 , 0"
      },
      composingStep: null
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  watch: {
    steps: function() {
      this.stepsMorePopverState = this.steps.map(() => false);
    }
  },

  async created() {
    this.impactKinds = await new CustomerJourneyProvider().impactKinds();
    switch (this.mode) {
      case "new":
        this.initializeANewJourneyStep();
        break;
      case "view":
        this.steps = this.journeySteps;
        break;
      case "edit":
        this.steps = this.journeySteps;
        if (this.steps.length == 0) {
          this.initializeANewJourneyStep();
        }
        break;
      default:
        break;
    }
    window.addEventListener("click", this.menuClose);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.menuClose);
  },

  mounted() {
    this.popoverOptions.popoverReference = this.$refs.popoverReference;
  },

  methods: {
    cumulativeScoreOfStep(step) {
      let scoreValue = Math.round(step.score());
      let score = this.impactScores.find(score => {
        return score.score == scoreValue;
      });
      return score;
    },

    moveStepUpward(step) {
      let currentOrder = step.order;
      let deletedStep = this.steps.splice(currentOrder - 1, 1).shift();
      deletedStep.order = deletedStep.order - 1;
      this.steps.splice(currentOrder - 2, 0, deletedStep);
      let affectedStep = this.steps.splice(currentOrder - 1, 1).shift();
      affectedStep.order = affectedStep.order + 1;
      this.steps.splice(currentOrder - 1, 0, affectedStep);
    },
    moveStepDownward(step) {
      let currentOrder = step.order;
      let deletedStep = this.steps.splice(currentOrder - 1, 1).shift();
      deletedStep.order = deletedStep.order + 1;
      this.steps.splice(currentOrder, 0, deletedStep);
      let affectedStep = this.steps.splice(currentOrder - 1, 1).shift();
      affectedStep.order = affectedStep.order - 1;
      this.steps.splice(currentOrder - 1, 0, affectedStep);
    },
    onBeginEditing(step) {
      this.archieveStep();
      let index = this.steps.findIndex(aStep => {
        return aStep.title == step.title;
      });
      this.restoreStepAtIndex(index);
    },
    onSelectScoreOnImpactKind(score, impactKind) {
      let found = false;
      this.impacts.forEach(impact => {
        if (impact.kind.name == impactKind.name) {
          impact.score = score;
          found = true;
          return;
        }
      });
      if (!found) {
        this.impactScores.forEach(impactScore => {
          if (impactScore.id == score.id) {
            this.impacts.push(new Impact(impactKind, score));
          }
        });
      }
    },

    onDelete(step) {
      Dialog.confirm({
        title: "Delete Step",
        message: "Are you sure to delete the step?"
      }).then(() => {
        this.steps.splice(step.order, 1);
        let order = -1;
        this.steps.forEach(step => {
          step.order = ++order;
        });
        if (order < 0) {
          this.order = 0;
        }
      });
    },

    onSave(editingStep = null) {
      let currentJourneyStepImpacts = [];
      if (this.impacts.length == 0) {
        this.impactKinds.forEach(impactKind => {
          currentJourneyStepImpacts.push(
            new Impact(impactKind, this.impactScores[0])
          );
        });
      } else {
        currentJourneyStepImpacts = this.impacts;
      }
      let step = new JourneyStep(
        this.order,
        this.title,
        this.description,
        currentJourneyStepImpacts,
        this.comment
      );
      if (editingStep) {
        this.steps.splice(this.order - 1, 1, step);
        this.clear();
      } else {
        this.steps.splice(this.order - 1, 1, step);
        this.clear();
      }
      this.onStepsChange(this.steps);
    },
    initializeANewJourneyStep() {
      this.order = this.steps.length + 1;
      this.steps.splice(this.steps.length, 0, this.aNewJourneyStep());
      this.impacts.splice(0, 3);
    },
    aNewJourneyStep() {
      let step = new JourneyStep(this.steps.length + 1);
      step.isComposing = true;
      return step;
    },
    archieveStep() {
      let editingStep = this.steps.find(aStep => {
        return aStep.isEditing;
      });
      let composingStep = this.steps.find(aStep => {
        return aStep.isComposing;
      });
      if (editingStep || composingStep) {
        let step = new JourneyStep(
          this.order,
          this.title,
          this.description,
          [],
          this.comment
        );
        step.isEditing = editingStep ? true : false;
        step.isComposing = composingStep ? true : false;
        this.steps.splice(this.order - 1, 1, step);
      }
    },
    restoreStepAtIndex(index) {
      let step = this.steps[index];
      this.order = step.order;
      this.title = step.title;
      this.description = step.description;
      step.isEditing = true;
      this.steps.splice(index, 1, step);
      this.comment = step.comment;
      this.impacts.splice(0);
      this.impacts.push(...step.impacts);
    },
    clear() {
      this.order = null;
      this.title = null;
      this.description = null;
      this.comment = null;
      this.impacts = [];
    },

    closePopover() {
      this.isPopoverVisible = false;
    },

    openPopover() {
      this.popoverOptions.popoverReference = this.$refs.popoverReference;

      this.$nextTick(() => {
        this.isPopoverVisible = true;
      });
    },

    toggleStepMorePopoverState(currentIndex) {
      let value = !this.stepsMorePopverState[currentIndex];
      this.stepsMorePopverState = this.stepsMorePopverState.map(() => false);
      this.stepsMorePopverState[currentIndex] = value;
      this.stepsMorePopverState.splice(
        0,
        this.stepsMorePopverState.length,
        ...this.stepsMorePopverState
      );
    },
    menuClose(e) {
      if (!this.$el.contains(e.target)) {
        for (let index = 0; index < this.stepsMorePopverState.length; index++) {
          this.stepsMorePopverState[index] = false;
        }
      }
    }
  }
};
</script>

<style src="@/assets/customerjourney/styles/stage.css" />
