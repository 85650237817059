import { Dialog } from "vant";

import { CurrentUser } from "@/helpers";
import { router } from "@/router";

export function defaultErrorHandler(error) {
  Dialog.alert({
    title: "Connectivity Problem",
    message:
      "We are facing some connectivity problem in reaching our servers. Please contact your mentors.",
    confirmButtonText: "Okay"
  });

  console.log(error); // eslint-disable-line no-console
}

export function defaultUnauthorizedRequestHandler() {
  router.push("/login");

  CurrentUser.clear();
}
