<template>
  <div class="team-dashboard">
    <van-cell-group>
      <van-cell title="My Score" :value="userPoints" />
      <van-cell title="My Position">
        <template>
          {{ userRank }} out of {{ totalTeamUsers }}
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PointProvider } from "@/providers";

export default {
  name: "TeamDashboardPage",
  components: {},
  data() {
    return {
      groupId: 4,
      userPoints: 0,
      userRank: 1,
      totalTeamUsers: 1
    };
  },

  mounted() {
    const self = this;

    PointProvider.getUserPoints(this.groupId).then(response => {
      self.userPoints = response.data.points;
    });
  },

  computed: {
    ...mapState("account", ["user"])
  }
};
</script>

<style scoped></style>
