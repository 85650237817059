<template>
  <div class="logo">
    <div class="top-nav">
      <div class="app-name">
        {{ appName }}
      </div>
    </div>

    <van-row>
      <van-col span="20" offset="2">
        <img width="40%" src="../assets/logo.jpg" alt="Tiny Magiq Logo" />
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { Url } from "@/helpers";

export default {
  name: "Logo",

  data() {
    return {
      appName: "Magiq Spark"
    };
  },

  props: {
    appId: {
      type: Number,
      default: 0
    }
  },

  created() {
    if (this.appId > 0) {
      this.appName = this.getAppName(this.appId);

      return;
    }

    const appId = parseInt(Url.getParam("app-id"));
    if (appId > 0) {
      this.appName = this.getAppName(appId);
    }
  },

  methods: {
    /**
     * Get the app name.
     *
     * @param appId
     * @returns {string}
     */
    getAppName(appId) {
      switch (appId) {
        case 1:
          return "Magiq Ears";
        default:
          return "Magiq Spark";
      }
    }
  }
};
</script>

<style scoped>
.logo {
  margin-bottom: 50px;
}

.top-nav {
  height: 50px;
  display: flex;
  flex-direction: row;
}

.app-name {
  font-size: 22px;
  font-weight: bold;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
</style>
