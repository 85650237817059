<template>
  <div id="app">
    <vue-drawer-layout
      ref="drawerLayout"
      :content-drawable="showMenu ? true : false"
      :backdrop="showMenu ? true : false"
      :enable="showMenu ? true : false"
    >
      <div class="menu" slot="drawer" v-if="isLoggedIn">
        <van-cell-group v-if="isLoggedIn">
          <van-cell
            value="Customer Journey"
            is-link
            to="/customerjourneymaps"
            icon="smile-comment-o"
            @click="toggleMenu"
          />
          <van-cell
            value="Journal"
            is-link
            to="/"
            icon="sign"
            @click="toggleMenuAndSetTab('journals')"
          />
          <van-cell
            value="Tasks"
            is-link
            to="/task-page"
            icon="todo-list-o"
            @click="toggleMenuAndSetTab('tasks')"
          />
          <van-cell
            value="Nudges"
            is-link
            to="/nudges"
            icon="notes-o"
            @click="toggleMenuAndSetTab('nudges')"
          />

          <!--          <van-cell-->
          <!--            value="Team Dashboard"-->
          <!--            is-link-->
          <!--            to="/team-dashboard"-->
          <!--            icon="info-o"-->
          <!--            @click="toggleMenu"-->
          <!--          />-->

          <van-cell
            value="My Profile"
            is-link
            to="/profile"
            icon="user-o"
            @click="toggleMenu"
          />

          <van-cell
            value="About"
            is-link
            to="/about"
            icon="info-o"
            @click="toggleMenu"
          />

          <van-cell
            value="Logout"
            is-link
            to="/login?logout=true"
            icon="close"
            @click="toggleMenu"
          />
        </van-cell-group>
      </div>

      <div class="content" slot="content">
        <div v-if="this.user">
          <van-nav-bar
            @click-left="toggleMenu"
            @click-right="handlerForAction"
            v-if="this.user"
            :title="this.user.displayName"
          >
            <van-icon name="bars" slot="left" size="xx-large" />
            <template #right v-if="hasAnyAction()">
              <van-icon :name="iconForAction()" size="xx-large" />
            </template>
          </van-nav-bar>
        </div>

        <router-view ref="homePage" />
      </div>
    </vue-drawer-layout>

    <div v-if="isLoggedIn">
      <van-tabbar
        v-model="active"
        active-color="#968fa3"
        inactive-color="#fff"
        class="tabbar"
      >
        <van-tabbar-item
          v-for="(item, index) in chips"
          :key="index"
          :name="item.label"
          @click="showPopup(index)"
          icon="home-o"
        >
          {{ item.label }}
        </van-tabbar-item>
      </van-tabbar>

      <van-popup
        v-model="show"
        closeable
        close-icon="close"
        position="bottom"
        :style="{ height: '80%' }"
      >
        <div class="popup_title">{{ popupTitle }}</div>
        <div class="popup_message">
          <iframe :src="popupData" class="popup_frame"></iframe>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { router } from "@/router";
import { ChipProvider, GroupSettingProvider } from "@/providers";

export default {
  name: "App",
  data() {
    return {
      reportUrl: false,
      customerJourney: null,
      active: "",
      show: false,
      popupTitle: "",
      popupData: "",
      chips: []
    };
  },

  computed: {
    ...mapState("account", ["user"]),

    isLoggedIn() {
      return !!this.user; // Checks if user is logged in
    },

    showMenu() {
      return (
        this.$route.name !== "login-page" &&
        this.$route.name !== "login-via-sms-page" &&
        this.$route.name !== "verify-otp" &&
        this.user
      );
    }
  },

  watch: {
    $route(to) {
      if (to.path.indexOf("customerjourneymaps") > 0) {
        let customerJourneyInfo = {};
        if (to.path.indexOf("customerjourneymaps/") > 0 ? to.params.id : null) {
          customerJourneyInfo = {
            ...customerJourneyInfo,
            id: to.params.id
          };
        }
        if (to.path.indexOf("/edit") > 0 ? to.params.id : null) {
          customerJourneyInfo = {
            ...customerJourneyInfo,
            mode: "edit"
          };
        }
        this.customerJourney = customerJourneyInfo;
      } else {
        this.customerJourney = null;
      }
    },

    user() {
      if (this.user) {
        this.getChips();
      }
    }
  },

  mounted() {
    if (this.user) {
      this.loadReportUrl();

      window.Event.listen("onReceivePushNotification", notification => {
        alert("Notification : " + notification[0]);
      });

      this.getChips();
    }
  },

  methods: {
    hasAnyAction() {
      return this.reportUrl || this.customerJourney;
    },

    iconForAction() {
      if (this.customerJourney) {
        if (this.customerJourney.id && this.customerJourney.mode != "edit") {
          return "edit";
        } else {
          return "close";
        }
      } else if (this.reportUrl) {
        return "medal";
      }
    },

    handlerForAction() {
      return this.customerJourney
        ? this.customerJourneyHandler()
        : this.reportUrl
        ? this.showReport()
        : null;
    },

    customerJourneyHandler() {
      if (this.customerJourney) {
        if (this.customerJourney.id && this.customerJourney.mode != "edit") {
          this.editCustomerJourney();
        } else {
          this.closeCustomerJourney();
        }
      }
    },

    toggleMenu() {
      if (this.showMenu) {
        this.$refs.drawerLayout.toggle();
      }
    },

    loadReportUrl() {
      GroupSettingProvider.getReportUrl().then(response => {
        if (response.data && response.data.length > 0) {
          this.reportUrl = response.data[0];
        }
      });
    },

    showReport() {
      if (this.reportUrl) {
        if (window.nsWebViewBridge) {
          // If this is inside native, then open the report url in the same tab.
          // @see https://github.com/tinymagiq/magiqspark/issues/1849
          location.replace(this.reportUrl);
        } else {
          window.open(this.reportUrl);
        }
      }
    },

    editCustomerJourney() {
      if (this.customerJourney.id) {
        window.location.assign(
          "/customerjourneymaps/" + this.customerJourney.id + "/edit"
        );
      }
    },

    closeCustomerJourney() {
      router.push({
        path: "/"
      });
    },

    showPopup(index) {
      const clickedItem = this.chips[index];
      this.show = true;
      this.popupTitle = clickedItem.label;
      this.popupData = clickedItem.url;

      const url = new URL(this.popupData);
      this.popupData = this.updatePopupData(url, this.user);
    },
    getChips() {
      ChipProvider.getChips().then(response => {
        this.chips = response.data;
      });
    },
    toggleMenuAndSetTab(tabName) {
      this.toggleMenu();
      this.$nextTick(() => {
        this.$refs.homePage.setActiveTab(tabName);
      });
    },
    updatePopupData(url, user) {
      const searchParams = new URLSearchParams(url.search);
      searchParams.set("id", user.id);
      searchParams.set("name", user.name);
      if (searchParams.has("id") && searchParams.has("name")) {
        return url.origin + url.pathname + "?" + searchParams.toString();
      }
      return url.origin + url.pathname;
    }
  }
};
</script>

<style>
html {
  margin: 0 auto;
  max-width: 400px;
}

body {
  background-color: #ffffff;
}

#app {
  color: #2c3e50;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@media (min-width: 500px) {
  .drawer-layout {
    border: 5px;
    border-style: ridge;
    max-width: 400px;
    margin: 0 auto;
  }
}

.van-dialog {
  max-width: 360px;
}

.drawer-layout,
.content-wrap {
  overflow: auto !important;
  margin-bottom: 25px;
}

.van-nav-bar {
  background-color: #5c038c !important;
}

.van-nav-bar__title {
  color: #ffffff !important;
}

.menu .van-cell__left-icon,
.van-nav-bar .van-nav-bar__right .van-icon {
  color: #5c038c;
  font-size: x-large;
}

.menu .van-cell__value span {
  font-size: 16px;
  font-weight: 600;
}

.content .van-nav-bar .van-icon-bars {
  color: #ffffff;
}

span.circle {
  background: #e3e3e3;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #ee0a24;
  display: inline-table;
  font-weight: bold;
  line-height: 20px;
  margin-right: 5px;
  text-align: center;
  width: 22px;
}

.sv_q_erbox {
  color: #ff4444;
}

.van-tabbar {
  background-color: #420264 !important;
}

.van-tabbar-item {
  font-weight: bold !important;
}

.popup_message {
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.popup_title {
  margin-top: 5px;
  font-weight: bold;
}

.popup_frame {
  width: 100%;
  flex: 1;
  border: none;
}
</style>
