import { api } from "@/api";

async function getSteps(status) {
  const response = await api.get(`steps/${status}`);

  return response.data;
}

async function getStep(stepId) {
  const response = await api.get(`step/${stepId}`);

  return response.data;
}

async function doesStepHasWhatsappNotification(stepId) {
  const response = await api.get(
    `step/${stepId}/journal-notifications/has-whatsapp`
  );

  return response.data;
}

async function getMatchStatus(stepId) {
  const response = await api.get(`step/${stepId}/match/status`);

  return response.data;
}

async function getMatch(stepId) {
  const response = await api.get(`step/${stepId}/match`);

  return response.data;
}

async function getMatchNotification(stepId) {
  const response = await api.get(`step/${stepId}/match/notification`);

  return response.data;
}

export const StepProvider = {
  getSteps,
  getStep,
  doesStepHasWhatsappNotification,
  getMatchStatus,
  getMatch,
  getMatchNotification
};
