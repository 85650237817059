<template>
  <VerifyOtp />
</template>

<script>
import VerifyOtp from "@/components/VerifyOtp";

export default {
  name: "VerifyOtpPage",
  components: {
    VerifyOtp
  }
};
</script>

<style scoped></style>
