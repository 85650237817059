import { StepProvider } from "@/providers";

const state = {
  steps: []
};

const actions = {
  getSteps({ commit }, status) {
    StepProvider.getSteps(status).then(steps => commit("setSteps", steps));
  }
};

const mutations = {
  setSteps(state, steps) {
    state.steps = steps;
  }
};

export const steps = {
  namespaced: true,
  state,
  actions,
  mutations
};
