import { api } from "@/api";

/**
 * Get user points.
 *
 * @returns {AxiosPromise<any>}
 */
async function getUserPoints(groupId) {
  return api.get(`group/${groupId}/points/`);
}

/**
 * Get user points and position details.
 *
 * @returns {AxiosPromise<any>}
 */
async function getUserRank(groupId) {
  return api.get(`group/${groupId}/user/rank/`);
}

export const PointProvider = {
  getUserPoints,
  getUserRank
};
