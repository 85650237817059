import { ProfileProvider } from "@/providers";

const state = {
  status: {
    updating: false,
    updateFailed: false,
    updated: false,
    changing: false,
    changeFailed: false,
    changed: false
  },
  profileInfo: []
};

const actions = {
  async updateProfile({ commit }, { params }) {
    commit("updateRequest", {});
    const unexpectedHandler = () => {
      commit("updateFailure");
    };
    await ProfileProvider.update({ unexpectedHandler }, params);
    commit("updateSuccess");
  },

  async getProfileInfo({ commit }) {
    const profileInfo = await ProfileProvider.getProfile();
    commit("setProfileInfo", profileInfo);
  }
};

const mutations = {
  updateRequest(state) {
    state.status = { updated: false, updating: true };
  },

  updateSuccess(state) {
    state.status = { updated: true, updating: false };
  },

  updateFailure(state) {
    state.status = { updated: false, updating: false, updateFailed: true };
  },

  setProfileInfo(state, profile) {
    state.profileInfo = profile;
  }
};

export const profile = {
  namespaced: true,
  state,
  actions,
  mutations
};
