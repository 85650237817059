import Vue from "vue";

import { router } from "./router";
import { store } from "./store";

import App from "./App.vue";

import "@/bootstrap/components";

import "./event.js";

import Popover from "vue-js-popover";
Vue.use(Popover);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
