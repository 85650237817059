<template>
  <div id="login">
    <Logo :app-id="parseInt(this.$route.params.appId)" />

    <van-loading type="spinner" color="#f44" v-if="loggingIn || sendingOTP" />

    <div class="prompt">
      <p>
        Enter the 4 digit code that was sent to {{ $route.params.email }}.
        <span @click="gotoEmailLogin" class="cursor">Change email</span>
      </p>
    </div>

    <div>
      <van-row>
        <van-col span="20" offset="2">
          <van-password-input
            :value="OTP"
            :length="4"
            :gutter="10"
            :focused="showOtpKeyboard"
            @focus="showOtpKeyboard = true"
          />
          <van-number-keyboard
            v-model="OTP"
            :hide-on-click-outside="false"
            :show="showOtpKeyboard"
            @blur="showOtpKeyboard = false"
          />
        </van-col>
      </van-row>

      <van-row>
        <van-col span="20" offset="2">
          <van-button
            type="primary"
            round
            text="Verify"
            size="large"
            @click="handleVerifyOTP"
            :disabled="!!(loggingIn || sendingOTP)"
          />
        </van-col>
      </van-row>

      <div style="margin-top:10px">
        <span v-if="!time">Didn't receive the code? </span>
        <span v-if="!time" @click="onClickResend" class="cursor">Resend</span>
        <timer v-if="time" :time="prettyTime" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Dialog } from "vant";

import { router } from "@/router";
import Timer from "./Timer.vue";
import { AuthProvider } from "@/providers";
import Logo from "@/components/Logo";
import { Login } from "@/helpers";

export default {
  name: "VerifyEmailOtp",

  components: {
    timer: Timer,
    Logo
  },

  data() {
    return {
      OTP: "",
      showOtpKeyboard: true,
      minutes: 0,
      seconds: 0,
      time: 300,
      timer: null
    };
  },

  mounted() {
    this.start();
  },

  computed: {
    ...mapState({
      sendingOTP: state => state.account.status.sendingOTP,
      sentOTP: state => state.account.status.sentOTP,
      loggingIn: state => state.account.status.loggingIn,
      loggedIn: state => state.account.status.loggedIn,
      loginFailed: state => state.account.status.loginFailed
    }),

    ...mapState("account", ["user"]),

    prettyTime() {
      const time = this.time / 60;
      const minutes = parseInt(time);
      const seconds = Math.round((time - minutes) * 60);

      return minutes + ":" + seconds;
    }
  },

  watch: {
    loggedIn(loggedIn) {
      if (!loggedIn) {
        return;
      }

      const nb = window.nsWebViewBridge;
      if (nb) {
        nb.emit("loggedIn", this.user);
      }

      AuthProvider.getMagiqsparkRedirectUrl().then(response => {
        if (response.data && false === response.data.redirect) {
          router.push("/");
        } else {
          window.location.href = response.data.redirect;
        }
      });
    },

    loginFailed(loginFailed) {
      if (!loginFailed) {
        return;
      }

      Dialog.alert({
        title: "Login failed",
        message:
          "The OTP you have entered is either invalid or expired. Try again.",
        confirmButtonText: "Okay"
      });

      this.OTP = "";
      this.loggingIn = false;
    },

    async OTP(value) {
      if (!value) {
        return;
      }

      if (value.length === 4) {
        await this.handleVerifyOTP();
      }
    }
  },

  created() {
    if (!this.$route.params.email) {
      this.gotoEmailLogin();
    }
  },

  methods: {
    ...mapActions("account", ["sendOTPEmail", "loginViaEmail", "logout"]),

    gotoEmailLogin() {
      let appId = 0;

      if (this.$route.params.appId) {
        appId = parseInt(this.$route.params.appId);
      }

      Login.gotoEmailLogin(appId);
    },

    async handleGetOTP() {
      await this.sendOTPEmail({
        email: this.$route.params.email
      });

      this.start();
    },

    async handleVerifyOTP() {
      if (!this.OTP) {
        return;
      }
      if (this.OTP.length === 4) {
        await this.loginViaEmail({
          email: this.$route.params.email,
          otp: this.OTP
        });
      }
    },

    async onClickResend() {
      await this.stop();
      await this.handleGetOTP();
    },

    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },

    stop() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style scoped>
.van-row {
  margin-bottom: 10px;
}

.van-row input {
  border-radius: 10em;
  height: 50px;
  line-height: 48px;
  padding-left: 10px;
  width: 97%;
}

.van-password-input {
  margin-top: -10px;
}

.cursor {
  cursor: pointer;
  text-decoration: underline;
}

.van-loading {
  z-index: 999;
}

.prompt {
  margin: 10px 10px 20px;
}
</style>
