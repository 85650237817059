<template>
  <div class="home">
    <van-tabs
      v-model="activeTab"
      sticky
      animated
      v-if="user.hasGroups"
      background="#EFDCF9"
      color="#420264"
      title-active-color="#1B1734"
      title-inactive-color="#1B1734"
    >
      <van-tab name="new" title-style="font-weight:600;">
        <div slot="title" :class="{ 'van-tab--active': activeTab === 'new' }">
          Open
        </div>
        <TaskList status="new" />
      </van-tab>

      <van-tab name="completed" title-style="font-weight:600;">
        <div
          slot="title"
          :class="{ 'van-tab--active': activeTab === 'completed' }"
        >
          Completed
        </div>
        <TaskList status="completed" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ProfileProvider } from "@/providers";
import TaskList from "@/components/TaskList.vue";

export default {
  name: "TaskPage",
  components: {
    TaskList
  },
  data() {
    return {
      activeTab: "new"
    };
  },

  computed: {
    ...mapState("account", ["user"])
  },

  mounted() {
    if (!this.user.hasGroups) {
      // If the user doesn't have any groups, let's try to retrieve again.
      this.loadUser();
    }
  },

  methods: {
    loadUser() {
      const self = this;
      ProfileProvider.getProfile().then(response => {
        self.user.hasGroups = response.has_groups;
      });
    }
  }
};
</script>

<style scoped>
.van-tabs {
  background-color: #f8f8f8 !important;
}

.van-tab .van-tab--active {
  font-weight: 600 !important;
  background-color: #ffffff !important;
}
</style>
