<template>
  <DoLoginViaSMS :mode="mode" />
</template>

<script>
import DoLoginViaSMS from "@/components/DoLoginViaSMS";

export default {
  name: "LoginViaSMSPage",
  components: {
    DoLoginViaSMS
  },
  props: {
    mode: String
  }
};
</script>

<style scoped></style>
