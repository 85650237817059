import { api } from "@/api";

async function getTasks(status) {
  const response = await api.get(`tasks/${status}`);

  return response.data;
}

async function getTask(taskId) {
  const response = await api.get(`task/${taskId}`);

  return response.data;
}

async function doTaskJournal(taskId, response) {
  return api.put(`task/${taskId}`, {
    response
  });
}

export const TaskProvider = {
  getTasks,
  getTask,
  doTaskJournal
};
