<template>
  <van-cell :border="true" size="large">
    <template #default>
      <div :class="contentStatusClass">
        <div class="container" v-if="expanded" @click="toggleExpanded">
          <div class="left-content">
            {{ fullContent }}
          </div>
          <div class="right-content">
            <van-icon
              name="arrow-up"
              size="25"
              v-if="contentLength > wordCount"
            />
          </div>
        </div>

        <div
          class="container"
          v-else
          @click="toggleExpanded(content.notification.id)"
        >
          <div class="left-content">
            {{ displayContent }}
            <span v-if="contentLength > wordCount">...</span>
          </div>
          <div class="right-content">
            <van-icon
              name="arrow-down"
              size="20"
              v-if="contentLength > wordCount"
            />
          </div>
        </div>
      </div>
    </template>
  </van-cell>
</template>

<script>
import { NotificationProvider } from "@/providers";

export default {
  props: {
    content: {
      type: Object,
      required: true
    },
    wordCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    displayContent() {
      return this.content.notification.message
        .split(" ")
        .slice(0, this.wordCount)
        .join(" ");
    },
    fullContent() {
      return this.content.notification.message;
    },
    contentLength() {
      return this.content.notification.message.split(/\s+/).length;
    },
    contentStatusClass() {
      if (this.content && this.content.status) {
        const readStatus = this.content.status.read_status;
        if (readStatus === "read" || readStatus === "unread") {
          return readStatus;
        }
      }
      return "read";
    }
  },
  methods: {
    async toggleExpanded(notificationId) {
      this.expanded = !this.expanded;
      await NotificationProvider.markNotificationAsRead(notificationId);
      this.$emit("markAsRead");
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
}
.left-content {
  width: 90%;
}
.right-content {
  width: 10%;
  text-align: right;
}
.content {
  cursor: pointer;
}
.read {
  font-weight: normal;
  color: #757575;
}
.unread {
  font-weight: bold;
  color: #000;
}
</style>
